@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
}

html {
  height: stretch;
  height: 100%;
}

body {
  font-family: 'Heading Pro Double', -apple-system, sans-serif;
  background: #fff;
  height: 100%;
  width: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

input:autofill {
  /* Expose a hook for JavaScript when auto fill is shown. */
  /* JavaScript can capture 'animationstart' events */
  animation-name: onAutoFillStart;
}

input:-webkit-autofill {
  /* Expose a hook for JavaScript when auto fill is shown. */
  /* JavaScript can capture 'animationstart' events */
  animation-name: onAutoFillStart;
}

@keyframes onAutoFillStart {
  from {
    /**/
  }
  to {
    /**/
  }
}

input:-webkit-autofill ~ .lbf-text-field__label-text, input:autofill ~ .lbf-text-field__label-text {
  @apply text-[12px] leading-[20px] top-[8px];
}

/* Hide password reveal button in MS Edge */
::-ms-reveal {
  display: none !important;
}

/* Hide autofill Safari icon */
::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
}